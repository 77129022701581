<template>
  <div>
    <MainAppBar>
      <template v-slot:title> Tradies ({{ tradieCount }}) </template>
      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn
            :to="{ name: 'tradies', query: { type: 'active' } }"
            depressed
            replace
            exact
          >
            Active Tradies
          </v-btn>

          <v-btn
            :to="{ name: 'tradies', query: { type: 'draft' } }"
            depressed
            replace
            exact
          >
            Draft Tradies
          </v-btn>
        </div>

        <v-btn
          class="bg-primary-gradient primary ml-auto px-7"
          :to="{ name: 'new-tradie' }"
          depressed
          rounded
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Tradie
        </v-btn>
      </template>
    </MainAppBar>

    <div class="mt-5 px-12 mb-10">
      <v-row>
        <v-col cols="12">
          <v-row align="center" style="max-width: 500px">
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search email, contact name, business name, or business type"
              v-model="filter.search"
              @input="search"
              hide-details
              clearable
              rounded
              flat
              solo
            />
          </v-row>
        </v-col>

        <v-col>
          <div
            class="mt-5 mb-10"
            id="tradie-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
          >
            <simple-table
              :loading="loading"
              :headers="tableHeaders"
              :sort="sort"
              :collection="tradies"
              @headerClicked="sortTradies"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { has, debounce } from 'lodash'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiPlus,
} from '@mdi/js'

import MainAppBar from '@/layouts/shared/MainAppBar'
import ControlsMixin from '@/utils/mixins/Controls'
import SimpleTable from '@/components/SimpleTable'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'TradiesPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    SimpleTable,
    MainAppBar,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,

      filter: {
        search: '',
      },

      sort: 'id',

      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        add: mdiPlus,
      },
    }
  },

  created() {
    this.clearTradies()
    this.fetchTradies(1)
  },

  destroyed() {
    this.clearTradies()
  },

  watch: {
    sort: function (newSort, oldSort) {
      this.clearTradies()
      this.fetchTradies()
    },

    isActiveTypeList() {
      this.clearTradies()
      this.fetchTradies()
    },
  },

  computed: {
    ...mapState({
      tradies: (state) => state.tradie.list,
      listMeta: (state) => state.tradie.listMeta,
    }),
    ...mapGetters('tradie', ['tradieCount']),

    paginationLength() {
      return this.listMeta.last_page | 0
    },

    tableHeaders() {
      return [
        { property: 'hash_id', name: 'Tradie ID' },
        {
          property: 'primary_contact_name',
          name: 'Contact Name',
        },
        { property: 'business_name', name: 'Business Name' },
        { property: 'business_area', name: 'Job Category' },
        { property: 'address', name: 'Business Address', sortable: false },
        {
          property: 'business_contact_number',
          name: 'Contact Number',
        },
        { property: 'email' },
        { property: 'abn', name: 'ABN' },
        { property: 'license_number', name: 'License No.' },
        { property: 'trade_license', name: 'Trade License Name' },
        { property: 'business_type' },
        {
          property: 'bank_accounts',
          name: 'Tradie Account Details',
          width: '20%',
          sortable: false,
        },
        {
          property: 'created_at',
          name: 'Date Added',
          sortable: true,
        },
      ]
    },

    isActiveTypeList() {
      return this.$route.query.type === 'active'
    },
  },

  methods: {
    ...mapActions({
      getTradies: 'tradie/getTradies',
    }),

    ...mapMutations({
      clearTradies: 'tradie/clearTradieList',
    }),

    pageChanged(page) {
      this.fetchTradies(page)
    },

    sortTradies(property) {
      this.sort = (this.sort.startsWith('-') ? '' : '-') + property
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchTradies(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearTradies()
      this.fetchTradies()
    }, 600),

    async fetchTradies(page = 1) {
      if (this.loading) return

      const params = {
        page,
        sort: this.sort,
        draft: !this.isActiveTypeList,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getTradies(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!has(to.query, 'type')) {
      return next({ name: 'tradies', query: { type: 'active' } })
    }

    next()
  },
}
</script>
<style scoped>
table > thead > tr > th {
  padding: 0 0px !important;
}
</style>
